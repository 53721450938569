
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { ElForm } from 'element-ui/types/form'
import { AddInfo } from '@/types/engineering'
import { FetchSuggestionsCallback } from 'element-ui/types/autocomplete'
import { twoDecimal } from '@/utils/validate'
import { DicInfo, DicList } from '../../types/common'

@Component({
  name: 'AddList'
})

export default class AddList extends Vue {
  private info: AddInfo = {
    engineeringName: '', // 工程名称
    contractForm: '', // 发包形式
    engineeringStage: '', // 工程阶段
    engineeringProvince: '', // 省
    engineeringCity: '', // 市
    engineeringArea: '', // 区
    engineeringAddr: '', // 具体地址
    longitude: '', // 经度
    latitude: '', // 纬度
    contractDate: '', // 合同日期
    tenderPrice: '', // 中标价格
    plantStart: '', // 计划开工日期
    plantEnd: '', // 计划竣工日期
    actualBeginDate: '', // 实际开工日期
    actualFinishedDate: '', // 实际竣工日期
    performanceMoneyDate: '', // 履约保证金日期
    engineeringAcreage: '', // 工程面积
    constructionUnit: '', // 施工单位
    designUnit: '', // 设计单位
    auditUnit: '', // 审计单位
    supervisorUnit: '' // 监理单位
  }

  private rules = {
    engineeringName: [
      { required: true, message: '请输入工程名称', trigger: ['blur', 'change'] }
    ],
    contractForm: [
      { required: true, message: '请选择发包形式', trigger: 'change' }
    ],
    engineeringStage: [
      { required: true, message: '请选择工程阶段', trigger: 'change' }
    ],
    engineeringAcreage: [
      { validator: twoDecimal }
    ],
    tenderPrice: [
      { validator: twoDecimal }
    ]
  }

  private addrList: object[] = []
  private addr: [string, string, string] = ['', '', '']
  private isAdding = false
  engineeringStageList: DicInfo[] = []
  contractFormList: DicInfo[] = []

  get editId () {
    return this.$route.params.id as string || ''
  }

  created () {
    this.getEngineeringList()
    this.getAddrList()
    this.getEngineeringStage()
    this.getContractForm()
  }

  // 获取工程信息
  getEngineeringList () {
    this.$axios.get(this.$apis.engineering.selectEngineeringInfoByInfoId, { infoId: this.editId }).then(res => {
      if (res) {
        this.info = res
        this.addr = [this.info.engineeringProvince, this.info.engineeringCity, this.info.engineeringArea]
      }
    })
  }

  // 获取工程阶段列表
  getEngineeringStage () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'engineeringStage' }).then(res => {
      this.engineeringStageList = res.engineeringStage
    })
  }

  // 获取发包形式列表
  getContractForm () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'contractForm' }).then(res => {
      this.contractFormList = res.contractForm
    })
  }

  // 获取地址信息
  getAddrList () {
    this.$axios.get(this.$apis.common.getRegion).then(res => {
      this.addrList = res || []
    })
  }

  // 省市区改变
  areaChange () {
    this.info.engineeringAddr = ''
    if (this.addr.length === 3 && this.addr[2] !== '') {
      this.info.engineeringProvince = this.addr[0] || ''
      this.info.engineeringCity = this.addr[1] || ''
      this.info.engineeringArea = this.addr[2] || ''
    } else {
      this.info.engineeringProvince = ''
      this.info.engineeringCity = ''
      this.info.engineeringArea = ''
    }
  }

  // 详细地址输入
  addrFetch (queryString: string, cb: FetchSuggestionsCallback) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.AutoComplete',
        'AMap.Geocoder'
      ]
    }).then(() => {
      // 根据所选区域初始化项目经纬度
      const geocoder = new AMap.Geocoder({
        city: this.info.engineeringCity
      })
      geocoder.getLocation(this.info.engineeringArea, (status, result) => {
        const res = result as AMap.Geocoder.GeocodeResult
        if (status === 'complete' && res.geocodes.length) {
          this.info.latitude = res.geocodes[0].location.getLat().toString()
          this.info.longitude = res.geocodes[0].location.getLng().toString()
        }
      })
      // 实例化Autocomplete
      const autoComplete = new AMap.Autocomplete({
        city: this.info.engineeringCity
      })
      autoComplete.search(this.info.engineeringArea + queryString, (status, result) => {
        const res = result as AMap.Autocomplete.SearchResult
        if (status === 'complete') {
          cb(res.tips)
        }
      })
    })
  }

  // 点击详细地址下拉
  addrChange (data: AMap.Autocomplete.Tip) {
    this.info.engineeringAddr = data.name
    this.info.latitude = data.location.getLat().toString()
    this.info.longitude = data.location.getLng().toString()
  }

  // 保存
  saveForm () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.isAdding = true
        const urls = this.editId ? this.$apis.engineering.updateEngineeringInfo : this.$apis.engineering.insertEngineeringInfo
        this.$axios.post(urls, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.back()
        }).finally(() => {
          this.isAdding = false
        })
      }
    })
  }
}

